import { zodResolver } from '@hookform/resolvers/zod'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { useMutation } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import Heading from '../../../../../components/Heading'
import Modal from '../../../../../components/Modal'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import ReactFormContainer from '../../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import RoundedButton, {
  RoundedButtonVariants,
} from '../../../../../components/RoundedButton/RoundedButton.component'
import { setShowSnackbar } from '../../../../../redux/slices/snackbarSlice'
import { setSpinner } from '../../../../../redux/slices/spinnerSlice'
import {
  WeakIdentificationRequestKeys,
  handleRequestToJoinOrganization,
} from '../../WeakIdentification.requests'
import {
  MoreInformationAboutRequest,
  MoreInformationAboutRequestType,
} from '../../WeakIdentification.schema'

type Props = {
  businessId: string
  onClose: () => void
  t: TFunction
  dispatch: Dispatch<AnyAction>
}

const StyledFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const MoreInformationDialogForm: React.FC<Props> = props => {
  const { businessId, onClose, t, dispatch } = props
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MoreInformationAboutRequestType>({
    defaultValues: {
      description: '',
      businessId,
    },
    resolver: zodResolver(MoreInformationAboutRequest),
  })

  const sendMoreInformationRequest = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.requestToJoin],
    mutationFn: handleRequestToJoinOrganization,
  })

  const onSubmit = (data: MoreInformationAboutRequestType) => {
    dispatch(setSpinner({ visible: true }))
    sendMoreInformationRequest.mutate(data, {
      onSuccess: () => {
        dispatch(setSpinner({ visible: false }))
        dispatch(
          setShowSnackbar({
            showSnackbar: true,
            error: false,
            message: t('weak-identification.join-to-organization-more-information-success'),
          })
        )
        onClose()
      },
      onError: () => {
        dispatch(setSpinner({ visible: false }))
        dispatch(
          setShowSnackbar({
            showSnackbar: true,
            error: true,
            message: t('weak-identification.join-to-organization-more-information-error'),
          })
        )
      },
    })
  }

  return (
    <Modal isOpen>
      <Heading color={'bf-blue'} level={'h5'}>
        {t('weak-identification.join-to-organization-more-information-title')}
      </Heading>
      <Paragraph>
        {t('weak-identification.join-to-organization-more-information-description')}
      </Paragraph>
      <ReactFormContainer onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor={'description'}>
          {t('weak-identification.join-to-organization-description-label')}
        </label>
        <textarea {...register('description')} />
        {errors.description ? (
          <span className="error-message">
            {t('weak-identification.join-to-organization-description-error')}
          </span>
        ) : null}
        <StyledFormFooter>
          <RoundedButton
            type={'button'}
            onClick={() => onClose()}
            variant={RoundedButtonVariants.secondary}
          >
            {t('weak-identification.join-to-organization-more-information-cancel')}
          </RoundedButton>
          <RoundedButton type={'submit'} variant={RoundedButtonVariants.primary}>
            {t('weak-identification.join-to-organization-more-information-submit')}
          </RoundedButton>
        </StyledFormFooter>
      </ReactFormContainer>
    </Modal>
  )
}

export default MoreInformationDialogForm
