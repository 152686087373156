import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

import { ColorEnum, Spacing } from '../../shared/enums'
import Heading from '../Heading'

type Props = {
  title: string
  children: ReactNode | ReactNode[]
}

const StyledInformationContainerWrapper = styled.div`
  & > div {
    width: 100%;
    padding: ${Spacing.Medium};
    background: ${ColorEnum.bfLightBlue};
  }
`

const InformationContainer: FunctionComponent<Props> = props => {
  const { title, children } = props

  return (
    <StyledInformationContainerWrapper>
      <div>
        <Heading color={'bf-blue'} level={'h6'}>
          {title}
        </Heading>
        {children}
      </div>
    </StyledInformationContainerWrapper>
  )
}

export default InformationContainer
