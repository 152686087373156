import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Heading from '../../../../../components/Heading'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import ReactFormContainer from '../../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import RoundedButton, {
  RoundedButtonVariants,
} from '../../../../../components/RoundedButton/RoundedButton.component'
import Spacer from '../../../../../components/Spacer'
import TermsAndConditions from '../../../../../components/TermsAndConditions/TermsAndConditions.component'
import { setShowSnackbar } from '../../../../../redux/slices/snackbarSlice'
import { setSpinner } from '../../../../../redux/slices/spinnerSlice'
import { Spacing } from '../../../../../shared/enums'
import {
  WeakIdentificationRequestKeys,
  handleWeakIdentificationMiscInformation,
} from '../../WeakIdentification.requests'
import {
  FormInput,
  FormInputElementDefaultProps,
  WeakIdentificationValidationMiscInformationStep,
  WeakIdentificationValidationMiscInformationStepType,
} from '../../WeakIdentification.schema'

// #region for styled-components

const MiscInformationFormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${Spacing.Large};
`

// #endregion for styled-components

/**
 * WeakIdentification MiscInformationForm step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const MiscInformationForm: FunctionComponent<FormInputElementDefaultProps> = props => {
  const { t, onSuccess, dispatch } = props
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WeakIdentificationValidationMiscInformationStepType>({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
    resolver: zodResolver(WeakIdentificationValidationMiscInformationStep),
  })

  const submitRequest = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.miscInformation],
    mutationFn: handleWeakIdentificationMiscInformation,
  })

  useEffect(() => {
    dispatch(setSpinner({ visible: submitRequest.isLoading }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitRequest.isLoading])

  /**
   * We send user data to the API and if this request is successful we navigate the user to the next step.
   * @param data
   */
  const onSubmit = (data: WeakIdentificationValidationMiscInformationStepType) => {
    submitRequest.mutate(data, {
      onSuccess: response => {
        dispatch(setSpinner({ visible: false }))

        if (!response.bfId) {
          console.warn('Error while trying to save user')
          dispatch(
            setShowSnackbar({
              error: false,
              showSnackbar: true,
              message: t('weak-identification.misc-information-plain-error'),
            })
          )

          throw new Error('Error while trying to save user')
        }

        dispatch(
          setShowSnackbar({
            error: false,
            showSnackbar: true,
            message: t('weak-identification.misc-information-plain-success'),
          })
        )
        onSuccess(FormInput.miscInformation)
      },
      onError: error => {
        console.warn(error)
        dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: t('weak-identification.misc-information-plain-error'),
          })
        )
      },
    })
  }

  /**
   * Cancels the registration and navigates user back to the main page.
   */
  const handleRouteToMainPage = () => {
    navigate('/')
  }

  return (
    <ReactFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Heading level={'h4'} color={'bf-blue'}>
        {t('weak-identification.misc-information-form-title')}
      </Heading>
      <Paragraph>{t('weak-identification.misc-information-form-description')}</Paragraph>
      <Spacer size={'large'} />
      <label htmlFor="firstName">{t('weak-identification.misc-information-form-first-name')}</label>
      <input type={'text'} {...register('firstName')} />
      {errors.firstName ? (
        <span className="error-message">
          {t('weak-identification.misc-information-form-first-name-error')}
        </span>
      ) : null}
      <label htmlFor="lastName">{t('weak-identification.misc-information-form-last-name')}</label>
      <input type={'text'} {...register('lastName')} />
      {errors.lastName ? (
        <span className="error-message">
          {t('weak-identification.misc-information-form-last-name-error')}
        </span>
      ) : null}
      <Spacer size={'large'} />
      <>
        <Heading level={'h6'} color={'bf-blue'}>
          {t('weak-identification.misc-information-form-policy-title')}
        </Heading>
        <Paragraph>{t('weak-identification.misc-information-form-policy-description')}</Paragraph>
        <Heading level={'h6'} color={'bf-blue'}>
          {t('weak-identification.misc-information-form-privacy-policy-title')}
        </Heading>
        <Paragraph>
          {t('weak-identification.misc-information-form-privacy-policy-description-row-one')}
        </Paragraph>
        <Paragraph>
          {t('weak-identification.misc-information-form-privacy-policy-description-row-two')}
        </Paragraph>
        <Spacer size={'large'} />
        <TermsAndConditions
          checkboxKey={'termsAndConditions'}
          errors={errors}
          t={t}
          register={register}
        />
        <MiscInformationFormFooter>
          <RoundedButton
            onClick={() => handleRouteToMainPage()}
            variant={RoundedButtonVariants.secondary}
            type={'button'}
          >
            {t('weak-identification.misc-information-form-cancel')}
          </RoundedButton>
          <RoundedButton type={'submit'} variant={RoundedButtonVariants.primary}>
            {t('weak-identification.misc-information-form-submit')}
          </RoundedButton>
        </MiscInformationFormFooter>
      </>
    </ReactFormContainer>
  )
}

export { MiscInformationForm }
