import { z } from 'zod'

import { isValidName } from '../../../../utils/validation-utils'

// Checks if string containts upper and lowercase characters
const UPPER_AND_LOWERCASE_CHECK = /.*[a-z].*[A-Z]|[A-Z].*[a-z].*/
// Checks if string contains a digit
const DIGIT_CHECK = /.*\d.*/
// Checks if the string contains special characters
const SPECIAL_CHARACTERS_CHECK = /.*[\W_]+.*/
// Checks if the string contains three consecutive characters
const THREE_CONSECUTIVE_CHARACTERS = /(\w)\1{2,}/
// Accepted characters for phone number
const ACCEPTED_PHONE_NUMBER_CHARACTERS = '0123456789-+() '

// #region Validation

/**
 * Password should consists of at least 16 characters, one uppercase letter, one lowercase letter, one digit and one special character.
 * If password contains three consecutive characters, it is invalid.
 */
const PasswordSchema = z
  .string()
  .min(16) // Ensure the value is not empty
  .refine(value => value.length >= 16, {
    message: 'account-creation.form-password-length',
  })
  .refine(value => UPPER_AND_LOWERCASE_CHECK.test(value), {
    message: 'account-creation.form-password-letters',
  })
  .refine(value => DIGIT_CHECK.test(value), {
    message: 'account-creation.form-password-digits',
  })
  .refine(value => SPECIAL_CHARACTERS_CHECK.test(value), {
    message: 'account-creation.form-password-characters',
  })
  .refine(value => !THREE_CONSECUTIVE_CHARACTERS.test(value), {
    message: 'account-creation.form-password-consecutive-characters',
  })

// Validates e-mail
export const EmailValidation = z.string().email()
// Validates verification key
export const verificationKeyValidation = z.string().min(7)

export const RegistrationFormSchema = z
  .object({
    firstname: z.string(),
    preferredFirstname: z
      .string()
      .min(1)
      .refine(firstname => {
        return isValidName(firstname)
      }),
    surname: z.string(),
    preferredSurname: z
      .string()
      .min(1)
      .refine(surname => {
        return isValidName(surname)
      }),
    ssn: z.string().optional(),
    email: EmailValidation,
    verificationKey: verificationKeyValidation,
    tel: z.string().refine(phone => {
      if (phone[0] !== '+') {
        return false
      }

      for (const char of phone) {
        if (!ACCEPTED_PHONE_NUMBER_CHARACTERS.includes(char)) {
          return false
        }
      }

      return true
    }),
    password: PasswordSchema,
    confirm: PasswordSchema,
    terms: z.boolean().refine(value => value),
  })
  .superRefine(data => {
    if (data.password !== data.confirm) {
      return {
        confirm: 'account-creation.form-passwordsmustmatch',
      }
    }

    return undefined
  })

// #endregion

// #region Type of the form values
export type RegistrationFormType = z.infer<typeof RegistrationFormSchema>
// #endregion
