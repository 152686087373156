/* eslint-disable react/jsx-no-bind */

/* eslint-disable padding-line-between-statements */
import { useMutation } from '@tanstack/react-query'
import { FormApi } from 'final-form'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import me from '../../../../api/me'
import Button from '../../../../components/Button/Button'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import FormContainer from '../../../../containers/FormContainer'
import useL3Access from '../../../../hooks/useL3Access'
import useValidators, { wrapWithStyledError } from '../../../../hooks/useValidators'
import { selectUserInfo } from '../../../../redux/slices/meSlice'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { FormErrors, FormValues } from '../../../../shared/types'
import { hasThreeConsecutive } from '../../../../utils/validation-utils'

const ChangePasswordForm: FunctionComponent = () => {
  const { t } = useTranslation()
  const { isSecurePassword } = useValidators()
  const [externalValidationErrors, setExternalValidationErrors] = useState()
  const hasL3Access = useL3Access()
  const dispatch = useDispatch()

  const userInfo = useSelector(selectUserInfo)
  const userEmail = userInfo?.email

  const changePasswordMutation = useMutation({
    mutationFn: (values: FormValues) => me.changePassword(values),
  })

  const formRef: React.MutableRefObject<FormApi | null> = useRef(null)

  const resetForm = () => formRef?.current?.reset()

  const handleSuccess = async () => {
    resetForm()
    dispatch(openSnackbar(t('snackbar.password-success'), false))
  }

  useEffect(() => {
    // TODO: Server errors
    if (changePasswordMutation.status === 'success' && changePasswordMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
      setExternalValidationErrors(changePasswordMutation.data.errors)
    } else if (changePasswordMutation.status === 'success' && !changePasswordMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
      handleSuccess()
    } else if (changePasswordMutation.status === 'error') {
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(t('snackbar.password-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordMutation.data, changePasswordMutation.status])

  // eslint-disable-next-line unicorn/consistent-function-scoping, @typescript-eslint/no-unused-vars
  const onSubmit = async (values: FormValues) => {
    dispatch(setSpinner({ visible: true }))
    changePasswordMutation.mutate(values)
  }

  return (
    <Container gap="none" padding="none">
      <Container padding="none" margin="none none small none">
        <Heading level="h2" visualLevel="h5" color="bf-blue">
          {t('user-profile.change-password')}
        </Heading>
      </Container>
      <FormContainer
        onSubmit={onSubmit}
        touchEnable={true}
        formRef={formRef}
        validator={(values: FormValues) => {
          const errors: FormErrors = {}

          if (values['confirm'] !== values['newPassword']) {
            errors['confirm'] = wrapWithStyledError(t('account-creation.form-passwordsmustmatch'))
          }

          if (
            values['newPassword'] &&
            userEmail &&
            hasThreeConsecutive(String(values['newPassword']), userEmail)
          ) {
            errors['newPassword'] = wrapWithStyledError(t('microcopies.form-password-consecutive'))
          }
          return errors
        }}
        externalValidationErrors={externalValidationErrors}
      >
        <FormContainer.Field
          label={t('user-profile.new-password')}
          type="password"
          name="newPassword"
          validators={[isSecurePassword]}
          helpText={t('account-creation.form-password-specification') || undefined}
          readOnly={!hasL3Access}
          isRequired
        />
        <FormContainer.Field
          label={t('user-profile.confirm-new-password')}
          type="password"
          name="confirm"
          readOnly={!hasL3Access}
          isRequired
        />
        {hasL3Access ? <Button type="submit" label={t('user-profile.change-password')} /> : null}
      </FormContainer>
    </Container>
  )
}

export default ChangePasswordForm
