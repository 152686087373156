import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import Button from '../../../../../components/Button'
import Heading from '../../../../../components/Heading'
import InformationContainer from '../../../../../components/InformationContainer/InformationContainer.component'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import ReactFormContainer from '../../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import RoundedButton, {
  RoundedButtonVariants,
} from '../../../../../components/RoundedButton/RoundedButton.component'
import Spacer from '../../../../../components/Spacer'
import { setShowSnackbar } from '../../../../../redux/slices/snackbarSlice'
import { setSpinner } from '../../../../../redux/slices/spinnerSlice'
import { Spacing } from '../../../../../shared/enums'
import {
  WeakIdentificationRequestKeys,
  handleWeakIdentificationCheckOrganization,
  handleWeakIdentificationJoinOrganization,
} from '../../WeakIdentification.requests'
import {
  FormInput,
  FormInputElementDefaultProps,
  WeakIdentificationJoinOrganizationStepType,
  WeakIdentificationValidationJoinToOrganizationStep,
} from '../../WeakIdentification.schema'
import { setSelectedBusinessId } from '../../WeakIdentification.utils'
import MoreInformationDialogForm from './MoreInformationDialogForm.component'

const JoinOrganizationFormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${Spacing.ExtraLarge};
`

/**
 * JoinnToOrganization step form.
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const JoinToOrganizationForm: FunctionComponent<FormInputElementDefaultProps> = props => {
  const { onSuccess, t, dispatch, service } = props

  // We will use react-form-hooks from now on. This is the first step to convert the form to react-form-hooks.
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<WeakIdentificationJoinOrganizationStepType>({
    defaultValues: {
      businessId: '',
      service,
    },
    resolver: zodResolver(WeakIdentificationValidationJoinToOrganizationStep),
  })
  const [openMoreInformationDialog, setOpenMoreInformationDialog] = useState<boolean>(false)
  const displayInformationContainer = false
  const submitRequest = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.joinToOrganization],
    mutationFn: handleWeakIdentificationJoinOrganization,
  })
  const checkBusinessId = useMutation({
    mutationKey: [WeakIdentificationRequestKeys.checkOrganization],
    mutationFn: handleWeakIdentificationCheckOrganization,
  })

  useEffect(() => {
    dispatch(setSpinner({ visible: checkBusinessId.isLoading || submitRequest.isLoading }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBusinessId.isLoading, submitRequest.isLoading])

  /**
   * This function sends a body with businessId to the API and if it's successful it will navigate the user to the next step.
   * @param data
   */
  const handleJoinToOrganization = (data: WeakIdentificationJoinOrganizationStepType) => {
    submitRequest.mutate(data, {
      onSuccess: response => {
        if (typeof response === 'string') {
          dispatch(
            setShowSnackbar({
              error: false,
              showSnackbar: true,
              message: t(response),
            })
          )
          dispatch(setSpinner({ visible: false }))
          // We set the selected business-id to session storage to use it on the next step
          setSelectedBusinessId(data.businessId)

          return onSuccess(FormInput.joinToOrganization)
        }
      },
      onError: error => {
        console.warn(error)
        dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: t(error as string),
          })
        )
      },
    })
  }

  /**
   * Skips the form submit and navigates the user to the next step.
   */
  const handleSkip = () => {
    onSuccess(FormInput.joinToOrganization)
  }

  /**
   * This function checks on the background does the user have privileges to join the organization via e-mail and business id.
   * @param businessId
   * @returns
   */
  const handleBusinessIdCheck = (businessId: string) => {
    clearErrors('businessId')

    if (businessId.trim().length === 0) {
      return
    }

    const body: WeakIdentificationJoinOrganizationStepType = {
      businessId,
      service: '',
    }
    // Validates the business id check
    const isValidBusinessId = WeakIdentificationValidationJoinToOrganizationStep.safeParse(body)

    // If the business id is not valid we set the errors
    if (!isValidBusinessId.success) {
      setError('businessId', {
        message: t('weak-identification.join-to-organization-invalid-business-id') as string,
      })

      return
    }

    // We call the API to check if the business id is valid or if the user has privileges to join the organization
    return checkBusinessId.mutate(body, {
      onSuccess: data => {
        if (data.hasErrors) {
          setError('businessId', {
            message: t('weak-identification.join-to-organization-no-privileges-to-join') as string,
          })

          return dispatch(
            setShowSnackbar({
              error: true,
              showSnackbar: true,
              message: t('weak-identification.join-to-organization-no-privileges-to-join'),
            })
          )
        }

        return dispatch(
          setShowSnackbar({
            error: false,
            showSnackbar: true,
            message: t('weak-identification.join-to-organization-organization-privileges-to-join', {
              organizationName: data.organizationName,
            }),
          })
        )
      },
      onError: error => {
        console.warn(error)
        setError('businessId', {
          message: t('weak-identification.join-to-organization-check-organization-error') as string,
        })

        return dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: t('weak-identification.join-to-organization-check-organization-error'),
          })
        )
      },
    })
  }

  return (
    <ReactFormContainer onSubmit={handleSubmit(handleJoinToOrganization)}>
      <Heading color={'bf-blue'} level={'h4'}>
        {t('weak-identification.join-to-organization-form-title')}
      </Heading>
      <Paragraph>
        {t('weak-identification.join-to-organization-form-description-row-one')}
      </Paragraph>
      <Paragraph>
        {t('weak-identification.join-to-organization-form-description-row-two')}
      </Paragraph>
      {displayInformationContainer ? (
        <InformationContainer
          title={t('weak-identification.join-to-organization-form-existing-organization-box-title')}
        >
          <Paragraph>
            {t(
              'weak-identification.join-to-organization-form-existing-organization-box-description-row-one'
            )}
          </Paragraph>
          <Paragraph>
            {t(
              'weak-identification.join-to-organization-form-existing-organization-box-description-row-two'
            )}
          </Paragraph>
          <Paragraph>
            {t(
              'weak-identification.join-to-organization-form-existing-organization-box-description-row-three'
            )}
          </Paragraph>
        </InformationContainer>
      ) : null}
      <Spacer size={'large'} />
      <label>{t('weak-identification.join-to-organization-business-id-label')}</label>
      <input
        type={'text'}
        {...register('businessId')}
        onBlur={e => handleBusinessIdCheck(e.target.value)}
        style={{ borderColor: checkBusinessId.data?.hasErrors ? 'red' : undefined }}
      />
      {errors.businessId ? (
        <>
          <span className="error-message">
            {errors.businessId.message ||
              t('weak-identification.join-to-organization-form-business-id-error')}
          </span>
          <Spacer size={'large'} />
          <Button
            label={t('weak-identification.join-to-organization-give-more-information-button')}
            variant={'primary'}
            onClick={() => setOpenMoreInformationDialog(true)}
          />
          {openMoreInformationDialog ? (
            <MoreInformationDialogForm
              dispatch={dispatch}
              t={t}
              businessId={watch('businessId')}
              onClose={() => setOpenMoreInformationDialog(false)}
            />
          ) : null}
        </>
      ) : null}
      {!checkBusinessId.data?.hasErrors && checkBusinessId.data ? (
        <span className="success-message">
          {t('weak-identification.join-to-organization-organization-privileges-to-join', {
            organizationName: checkBusinessId.data?.organizationName,
          })}
        </span>
      ) : null}

      <JoinOrganizationFormFooter>
        <RoundedButton
          variant={RoundedButtonVariants.secondary}
          type={'button'}
          onClick={() => handleSkip()}
        >
          {t('weak-identification.join-to-organization-form-cancel')}
        </RoundedButton>
        <RoundedButton
          disabled={
            checkBusinessId.isLoading ||
            submitRequest.isLoading ||
            checkBusinessId.isError ||
            checkBusinessId?.data?.hasErrors
          }
          variant={RoundedButtonVariants.primary}
          type={'submit'}
        >
          {t('weak-identification.join-to-organization-form-submit')}
        </RoundedButton>
      </JoinOrganizationFormFooter>
    </ReactFormContainer>
  )
}

export { JoinToOrganizationForm }
