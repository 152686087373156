import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import me, { MeKeys } from '../../../../api/me'
import { ValidationKeys, validateEmail } from '../../../../api/validations'
import Button from '../../../../components/Button/Button'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import Input from '../../../../components/Input'
import { LoadingIndicator } from '../../../../components/LoadingIndicator/LoadingIndicator.component'
import { QUERY_KEY_USER_PROFILE } from '../../../../constants'
import EmailVerificationModal from '../../../../containers/EmailVerificationModal'
import useL3Access from '../../../../hooks/useL3Access'
import { InputType } from '../../../../shared/enums'
import { IUserInformation } from '../../../../shared/interfaces'
import {
  ChangeEmailFormType,
  ChangeEmailFormValidation,
  EmailChangeFormType,
  EmailChangeFormValidation,
} from './ChangeEmailForm.schema'

type Props = {
  data: IUserInformation | undefined
}

const ChangeEmailForm: FunctionComponent<Props> = props => {
  const { t } = useTranslation()

  const { data } = props

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm<ChangeEmailFormType>({
    resolver: zodResolver(ChangeEmailFormValidation),
    defaultValues: {
      emailVerification: data?.email,
      currentEmail: data?.email,
    },
  })

  const hasL3Access = useL3Access()
  const queryClient = useQueryClient()

  const validateEmailRequest = useMutation({
    mutationKey: [ValidationKeys.Email],
    mutationFn: validateEmail,
  })

  const changeEmailRequest = useMutation({
    mutationFn: me.changeEmail,
    mutationKey: [MeKeys.UpdateInfo],
  })

  /**
   * This function is triggered on form submit. It checks if the e-mail is valid in ubi
   * and if it is
   * @param values
   * @returns
   */
  const onSubmit = async (values: ChangeEmailFormType) => {
    const validity = await validateEmailRequest.mutateAsync(values.emailVerification)

    if (validity.errors && validity.errors.length > 0) {
      const message = validity.errors.map(error => error.message).join(', ')

      return setError('emailVerification', { message })
    }
  }

  /**
   * On after submitting the verification key, this function is triggered to change the email.
   * @param verificationKey
   * @returns {void}
   */
  const handleChangeEmail = (verificationKey: string) => {
    const payload: EmailChangeFormType = {
      email: getValues('emailVerification'),
      verificationKey,
    }
    const isValid = EmailChangeFormValidation.safeParse(payload)

    if (!isValid.success) {
      return
    }

    changeEmailRequest.mutate(payload, {
      onSuccess: () => {
        // We refetch the user profile to get the updated data
        queryClient.invalidateQueries([QUERY_KEY_USER_PROFILE])
        // We reset the form and close the modal by this way
        validateEmailRequest.reset()
      },
    })
  }

  /**
   * Handles onBlur event for the input fields. Due we are using react-hook-form it's quite
   * hard to easily inject ...register() function to the existing ui-component without making
   * a major rework with them we handle the onBlur event in this way.
   * @param value
   * @param name
   */
  const handleOnBlur = (value: string, name: keyof ChangeEmailFormType) => {
    setValue(name, value)
  }

  return (
    <Container gap="none" padding="none">
      <Container padding="none" margin="none none small none">
        <Heading level="h2" visualLevel="h5" color="bf-blue">
          {t('user-profile.account')}
        </Heading>
      </Container>
      {!data ? (
        <div>
          <LoadingIndicator height={60} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type={InputType.Email}
            defaultValue={data?.email}
            label={t('microcopies.form-label-account-email')}
            readOnly={!hasL3Access}
            hasError={!!errors.emailVerification}
            onChange={() => clearErrors('emailVerification')}
            errorMessage={
              errors.emailVerification?.message
                ? (t(errors.emailVerification?.message) as string)
                : undefined
            }
            onBlur={e => handleOnBlur(e.target.value, 'emailVerification')}
            name={'emailVerification'}
          />
          {hasL3Access ? (
            <Button
              label={t('user-profile.email-verify-save')}
              disabled={
                !hasL3Access || !!errors.emailVerification || validateEmailRequest.isLoading
              }
              type={'submit'}
            />
          ) : null}
        </form>
      )}
      {hasL3Access && validateEmailRequest.isSuccess ? (
        <EmailVerificationModal
          email={getValues('emailVerification')}
          isOpen
          onAfterSubmit={handleChangeEmail}
          onCancel={() => validateEmailRequest.reset()}
        />
      ) : null}
    </Container>
  )
}

export { ChangeEmailForm }
