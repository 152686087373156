import { FunctionComponent } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import HeaderContainer from '../../containers/HeaderContainer'

/**
 * **Registration route**
 *
 * @returns nested registration routes
 */
const Register: FunctionComponent = () => {
  const location = useLocation()

  let showReturnButton = false

  if (location.pathname === '/register') showReturnButton = true

  return (
    <>
      <HeaderContainer showReturnButton={showReturnButton} />
      <Outlet />
    </>
  )
}

export default Register
