import { ButtonHTMLAttributes, FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum, Spacing } from '../../shared/enums'

export enum RoundedButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: RoundedButtonVariants
  children: React.ReactNode
}

const StyledRoundedButton = styled.button<Props>`
  padding: ${Spacing.Medium} ${Spacing.Large};
  border-radius: ${Spacing.Large};
  border: 2px solid ${ColorEnum.bfBlue};
  cursor: pointer;
  text-transform: uppercase;
  color: ${props =>
    props.variant === RoundedButtonVariants.primary ? ColorEnum.white : ColorEnum.bfBlue};
  background-color: ${props =>
    props.variant === RoundedButtonVariants.primary ? ColorEnum.bfBlue : ColorEnum.white};
`

/**
 * UI component for rounded button. Difference between normal button and this is basically
 * that this button has some additional styling. It takes all the normal props that a button
 * has and includes also the variant prop which is used to determine the color of the button.
 * Children of this component are the text that is displayed on the button in uppercase.
 * @param props
 * @returns {FunctionComponent<Props>}
 */
const RoundedButton: FunctionComponent<Props> = props => {
  const { variant, children, ...rest } = props

  return (
    <StyledRoundedButton variant={variant} {...rest}>
      {children}
    </StyledRoundedButton>
  )
}

export default RoundedButton
