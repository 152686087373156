import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ColorEnum } from '../../shared/enums'

interface Props extends React.HTMLAttributes<HTMLFormElement> {
  children: React.ReactNode[]
}

const StyledFormContainer = styled.form`
  & > label {
    text-transform: uppercase;
    display: block;
  }
  & > input,
  textarea {
    border: 1px solid ${ColorEnum.bfBlue};
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: ${ColorEnum.white};
    text-transform: none;
    margin-bottom: 0.5rem;
  }
  & > input:read-only,
  textarea:read-only {
    background: ${ColorEnum.bfLightGray} !important;
  }
  & > .checkbox-row {
    display: flex;
    align-items: center;
  }
  .checkbox-row > input {
    border: 1px solid ${ColorEnum.bfBlue};
    border-radius: 3px;
    min-width: 1.625rem;
    min-height: 1.625rem;
    background-color: ${ColorEnum.bfLightGray};
    all: revert !important;
  }
  .checkbox-row > label {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    color: #002ea2;
  }
  .error-message {
    color: ${ColorEnum.bfRed} !important;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .checkbox-error-message {
    color: ${ColorEnum.bfRed} !important;
    padding-left: 0.25rem;
    font-weight: bold;
  }
  .success-message {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: ${ColorEnum.bfGreen};
  }
  .help-text {
    font-size: 0.9rem;
    font-weight: 400;
    white-space: pre-line;
    margin-top: calc(-0.5rem + 5px);
    margin-bottom: 1rem;
    display: block;
  }
  .pull-right {
    display: flex;
    justify-content: flex-end;
  }
`

const ReactFormContainer: FunctionComponent<Props> = (props: Props) => {
  const { children, ...rest } = props

  return (
    <StyledFormContainer className="bfsso-form" {...rest}>
      {children}
    </StyledFormContainer>
  )
}

export default ReactFormContainer
