import { FunctionComponent, MouseEventHandler, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import Button from '../../../../components/Button'
import Container from '../../../../components/Container'
import { Heading3 } from '../../../../components/Heading/Heading.style'
import Text from '../../../../components/Text/Text'
import {
  BASE_URL,
  DEFAULT_SERVICE_ID,
  SESSION_KEY_REDIRECT,
  SESSION_KEY_SERVICE,
  STORAGE_KEY_LAST_ORGANIZATION,
} from '../../../../constants'
import useAuth from '../../../../hooks/useAuth'
import { selectAuthLevel } from '../../../../redux/slices/meSlice'
import { IServiceEntity } from '../../../../shared/interfaces'
import { authLevelToAcr } from '../../../../utils/auth-utils'
import { handleUserServicePatch } from '../../../Login/Organization/SelectOrganization/SelectOrganization'

interface SuccessStep {
  loginPage: () => void
}

const SuccessStep: FunctionComponent<SuccessStep> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  //Updates UID to the state = user is registered
  useAuth()
  const authLevel = useSelector(selectAuthLevel)

  const storedService = sessionStorage.getItem(SESSION_KEY_SERVICE)
  const service: IServiceEntity | undefined = storedService ? JSON.parse(storedService) : undefined

  const handleRedirect: MouseEventHandler = useCallback(
    async _event => {
      if (service) {
        const selectedOrg = localStorage.getItem(STORAGE_KEY_LAST_ORGANIZATION)

        const validationEndpoint = new URL(`/api/services/${service.id}/redirect`, BASE_URL)

        if (service.redirection?.url) {
          const redirectUrl = new URL(service.redirection.url)

          redirectUrl.searchParams.append('prompt', 'login')

          if (selectedOrg) {
            redirectUrl.searchParams.append('selectedOrg', selectedOrg)
            localStorage.removeItem(STORAGE_KEY_LAST_ORGANIZATION)
          }

          if (authLevel) {
            const acr = authLevelToAcr(authLevel)
            redirectUrl.searchParams.append('acr_values', acr)
          }

          validationEndpoint.searchParams.append('redirectUrl', redirectUrl.toString())
        }

        const res = await fetch(validationEndpoint.toString())
        const data = await res.json()

        await handleUserServicePatch(service.id)

        if (data.redirectUrl) {
          sessionStorage.setItem(SESSION_KEY_REDIRECT, data.redirectUrl)
          window.location.assign(data.redirectUrl)
        }
      }
    },
    [authLevel, service]
  )

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handlePortal = async () => {
    navigate('/portal')
  }

  return (
    <Container padding="none">
      <Heading3 as="h1">{t('success-step.title')}</Heading3>
      <Text as="p">{t('success-step.body')}</Text>
      <Container textAlign="center" padding="none" gap="large" alignItems="flex-start">
        {service && service.id !== DEFAULT_SERVICE_ID ? (
          <Button
            label={t('success-step.button-redirect', {
              serviceName: service.name,
            })}
            onClick={handleRedirect}
          />
        ) : (
          <Button label={t('success-step.button-signin')} onClick={handlePortal} />
        )}
      </Container>
    </Container>
  )
}

export default SuccessStep
