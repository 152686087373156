import { handleAPIGet, handleAPIPost } from '../../../utils/network-utils'
import {
  MoreInformationAboutRequestType,
  OnBoardingStatusType,
  WeakIdentificationJoinOrganizationStepType,
  WeakIdentificationMiscInformationRequestResponseType,
  WeakIdentificationPrivilegeCheckResponseType,
  WeakIdentificationValidationMiscInformationStepType,
} from './WeakIdentification.schema'

/**
 * Store query- and mutationkeys in the same place as requests
 */
export enum WeakIdentificationRequestKeys {
  authenticationStep = 'WEAK_IDENTICATION_AUTHENTICATION_STEP',
  miscInformation = 'WEAK_IDENTICATION_MISC_INFORMATION_STEP',
  checkOrganization = 'WEAK_IDENTICATION_CHECK_ORGANIZATION',
  joinToOrganization = 'WEAK_IDENTICATION_JOIN_TO_ORGANIZATION',
  session = 'WEAK_IDENTICATION_SESSION',
  requestToJoin = 'WEAK_IDENTIFICATION_REQUEST_TO_JOIN',
  onBoardingStatus = 'WEAK_IDENTIFICATION_ONBOARDING_STATUS',
}

// #region starts for the requests

/**
 * Fetches the authorization link for the weak identication
 * @param type
 * @returns {Promise<string>}
 */
export const handleWeakIdentificationAuthorizationLink = async (): Promise<string> => {
  return await handleAPIGet<string>('/weak-identification')
}

/**
 * Saves misc information about the user
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdentificationMiscInformation = async (
  body: WeakIdentificationValidationMiscInformationStepType
): Promise<WeakIdentificationMiscInformationRequestResponseType> => {
  return await handleAPIPost<
    WeakIdentificationMiscInformationRequestResponseType,
    WeakIdentificationValidationMiscInformationStepType
  >('/weak-identification/misc-information', body)
}

/**
 * Checks if organizations business id is valid
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdentificationCheckOrganization = async (
  body: WeakIdentificationJoinOrganizationStepType
): Promise<WeakIdentificationPrivilegeCheckResponseType> => {
  return await handleAPIPost<
    WeakIdentificationPrivilegeCheckResponseType,
    WeakIdentificationJoinOrganizationStepType
  >('/weak-identification/check-organization', body)
}

/**
 * Joins to an organization
 * @param body
 * @returns {Promise<unknown>}
 */
export const handleWeakIdentificationJoinOrganization = async (
  body: WeakIdentificationJoinOrganizationStepType
): Promise<string> => {
  return await handleAPIPost<string, WeakIdentificationJoinOrganizationStepType>(
    '/weak-identification/join-to-organization',
    body
  )
}
/**
 * Checks if user has a valid session
 * @returns {Promise<boolean>}
 */
export const handleSessionCheckForWeakIdentification = async (): Promise<boolean> => {
  return await handleAPIGet<boolean>('/weak-identification/session')
}

/**
 * Sends a request to join an organization by e-mail
 * @param body
 * @returns {Promise<string>}
 */
export const handleRequestToJoinOrganization = async (
  body: MoreInformationAboutRequestType
): Promise<string> => {
  return await handleAPIPost<string, MoreInformationAboutRequestType>(
    '/weak-identification/request-to-join',
    body
  )
}

/**
 * Gets the onboarding status
 * @returns {Promise<OnBoardingStatusType>}
 */
export const handleOnBoardingStatus = async (): Promise<OnBoardingStatusType> => {
  return await handleAPIGet<OnBoardingStatusType>('/weak-identification/onboarding-status')
}
// #endregion for the requests
