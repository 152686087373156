/* eslint-disable react/iframe-missing-sandbox */
import { RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Heading from '../../components/Heading/Heading'
import { Container } from '../../components/Input/Input.style'
import { SESSION_KEY_REDIRECT } from '../../constants'
import { ColorEnum } from '../../shared/enums'
import tokens from './../../api/tokens'

const PasswordLogin = () => {
  const { t: translate } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const { i18n } = useTranslation()

  const iframeRef: RefObject<HTMLIFrameElement> = useRef(null)

  const redirectUrl = '/organization'

  sessionStorage.setItem(SESSION_KEY_REDIRECT, redirectUrl)

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handlePasswordLogin = async () => {
    const response = await tokens.initiateWeakAuthProcess()

    if (iframeRef.current !== null) {
      iframeRef.current.src = `${response?.authRedirUrl}&locale=${i18n.language}`
      const iframeDocument = iframeRef.current.contentDocument

      if (iframeDocument) {
        iframeDocument.body.style.display = 'flex'
        iframeDocument.body.style.alignItems = 'center'
        iframeDocument.body.style.justifyContent = 'center'
      }
    }
  }

  useEffect(() => {
    if (!isLoading && iframeRef?.current?.src) {
      const url = new URL(iframeRef.current.src)
      url.searchParams.set('locale', `${i18n.language}`)

      iframeRef.current.src = url.toString()
    }
  }, [i18n.language, isLoading])

  if (isLoading) {
    handlePasswordLogin()
    setIsLoading(false)
  }

  return (
    <Container>
      <hr
        style={{
          borderTop: '1px solid #000',
          margin: '10px 0',
          color: ColorEnum.bfBlue,
        }}
      />{' '}
      <Heading level="h3" visualLevel="h4" color="bf-blue">
        {translate('microcopies.or')}
      </Heading>
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <iframe
          style={{ overflow: 'hidden', border: 'none' }}
          ref={iframeRef}
          name="Login with username and password"
          height={360}
          width={'100%'}
          title={`${translate('registration-login.weak-identification-button-label')}`}
          sandbox="allow-forms allow-same-origin allow-top-navigation allow-scripts"
          referrerPolicy="origin"
        />
      )}
    </Container>
  )
}

export default PasswordLogin
