import { useQuery } from '@tanstack/react-query'
import { MouseEventHandler, useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import tokens from '../../../api/tokens'
import Button from '../../../components/Button'
import Container from '../../../components/Container'
import Heading from '../../../components/Heading'
import Link from '../../../components/Link'
import {
  DEFAULT_SERVICE_ID,
  DEFAULT_SERVICE_NAME,
  ENVIRONMENT,
  QUERY_KEY_AUTH_START_L3,
  SESSION_KEY_REDIRECT,
  SESSION_KEY_SERVICE,
  SUOMI_FI_LINKS,
} from '../../../constants'
import HeaderContainer from '../../../containers/HeaderContainer'
import RouteWrapper from '../../../containers/RouteWrapper'
import useService from '../../../hooks/useService'
import { WeakIdentificationUrls } from '../../../routes/Register/WeakIdentification/WeakIdentification.urls'
import { ColorEnum, Spacing } from '../../../shared/enums'
import PasswordLogin from '../PasswordLogin'
import { ACRValues, AuthenticationLevels } from './Authenticate.schema'

const Main = styled.main`
  width: 100%;
  background-color: ${ColorEnum.white};
  color: ${ColorEnum.black};
`

const ContentWrapper = styled.div`
  max-width: 44.625rem;
  margin: auto;
  padding: ${Spacing.ExtraLarge};
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const Authenticate = () => {
  const { t: translate, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { isLoading, service } = useService()
  const invitationId = searchParams.get('invitationId')

  const suomiQuery = useQuery(
    [QUERY_KEY_AUTH_START_L3],
    () => tokens.initiateAuthProcess(ACRValues.ESuomi),
    {
      enabled: false,
    }
  )

  const suomiFiLink = useMemo(() => {
    return SUOMI_FI_LINKS[i18n.language]
  }, [i18n.language])

  const handleSid: MouseEventHandler = useCallback(
    _event => {
      const redirectUrl =
        service?.redirection?.preferences.requireOrgSelection || service?.redirection?.url
          ? '/organization'
          : '/portal'

      sessionStorage.setItem(SESSION_KEY_REDIRECT, redirectUrl)
      suomiQuery.refetch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suomiQuery.refetch, service]
  )

  useEffect(() => {
    if (suomiQuery.status === 'success' && !!suomiQuery.data.authRedirUrl) {
      window.location.assign(suomiQuery.data.authRedirUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suomiQuery.data, suomiQuery.status])

  useEffect(() => {
    if (invitationId) sessionStorage.setItem('invitationId', invitationId)
  }, [invitationId])

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleSidTupas = async () => {
    const response = await tokens.initiateAuthProcess(ACRValues.Tupas)
    window.location.assign(response?.authRedirUrl)
  }

  /**
   * This function reforwards the user to the e-mail login.
   */
  const handleWeakIdentificationReforward = () => {
    navigate(WeakIdentificationUrls.emailStep)
  }

  // TODO: Add loader
  if (isLoading) return null

  // If service is found, set it in session storage
  if (service) {
    sessionStorage.setItem(SESSION_KEY_SERVICE, JSON.stringify(service))
  }

  if (!service || service.id === DEFAULT_SERVICE_ID) {
    fetch('/api/services/default', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  return (
    <RouteWrapper title={translate('registration-login.title')}>
      <HeaderContainer />
      <Main>
        <ContentWrapper>
          <Heading level="h2" visualLevel="h4" color="bf-blue">
            {translate('registration-login.subtitle', {
              serviceName: service?.redirection?.name ?? service?.name ?? DEFAULT_SERVICE_NAME,
            })}
          </Heading>
          <Container padding="none" color="bf-blue">
            {service?.redirection?.preferences.requiredAuthLevel !== AuthenticationLevels.L1 && (
              <>
                <div>
                  <Trans
                    i18nKey="strong-identification.ingress"
                    components={{
                      link1: <Link href={suomiFiLink}>Suomi.fi</Link>,
                    }}
                  />
                </div>
                <Container textAlign="center" padding="none" gap="large" alignItems="flex-start">
                  <Button
                    label={translate('registration-login.strong-identification-button-label')}
                    onClick={handleSid}
                  />
                  {ENVIRONMENT === 'development' || ENVIRONMENT === 'test' ? (
                    <Button
                      label={translate('registration-login.tupas-button-label')}
                      onClick={handleSidTupas}
                    />
                  ) : null}
                </Container>
                {service?.redirection?.preferences.requiredAuthLevel ===
                AuthenticationLevels.L3 ? null : (
                  <Container
                    padding="none"
                    margin="extra-large none none none"
                    gap="none"
                    textAlign="center"
                  >
                    <PasswordLogin />
                  </Container>
                )}
              </>
            )}
            {service?.redirection?.preferences.requiredAuthLevel === AuthenticationLevels.L1 && (
              <Container textAlign="center" padding="none" gap="large" alignItems="flex-start">
                <Button
                  onClick={() => handleWeakIdentificationReforward()}
                  label={translate('registration-login.l1-identification-button-label')}
                />
              </Container>
            )}
          </Container>
        </ContentWrapper>
      </Main>
    </RouteWrapper>
  )
}

export default Authenticate
