import { FunctionComponent } from 'react'
import styled from 'styled-components'

import Heading from '../../../../../components/Heading'
import Paragraph from '../../../../../components/Paragraph/Paragraph.component'
import RoundedButton, {
  RoundedButtonVariants,
} from '../../../../../components/RoundedButton/RoundedButton.component'
import Spacer from '../../../../../components/Spacer'
import { BASE_URL, SESSION_KEY_SERVICE } from '../../../../../constants'
import { ACRValues } from '../../../../../routes/Login/Authenticate/Authenticate.schema'
import { Spacing } from '../../../../../shared/enums'
import { FormInputElementDefaultProps } from '../../WeakIdentification.schema'
import {
  getSelectedBusinessId,
  setWeakIdentificationRedirectUrl,
} from '../../WeakIdentification.utils'

const StyledSuccessSimpleWrapper = styled.div`
  padding-top: ${Spacing.Large};
  padding-bottom: ${Spacing.Large};
`

/**
 * This view just shows a success message and a button to navigate to the next path
 * @param props
 * @returns {FunctionComponent<FormInputElementDefaultProps>}
 */
const SuccessSimple: FunctionComponent<FormInputElementDefaultProps> = props => {
  const { t } = props
  // This is a placeholder for the email
  const selectedBusinessId = getSelectedBusinessId()
  const storedService = sessionStorage.getItem(SESSION_KEY_SERVICE)

  const handleRedirect = async () => {
    if (!storedService) {
      console.warn('Service is missing')

      return
    }

    try {
      const service = JSON.parse(storedService)
      const storedServiceRedirectUrl = service.redirection?.url

      if (!storedServiceRedirectUrl) {
        console.warn('Service redirect url is missing')

        return
      }

      const validationEndpoint = new URL(`/api/services/${service?.id}/redirect`, BASE_URL)
      const redirectUrl = new URL(storedServiceRedirectUrl)
      redirectUrl.searchParams.append('prompt', 'login')

      if (selectedBusinessId) {
        redirectUrl.searchParams.append('selectedOrg', selectedBusinessId)
      }

      redirectUrl.searchParams.append('acr_values', ACRValues.EmailConfirmation)
      validationEndpoint.searchParams.append('redirectUrl', redirectUrl.toString())
      const res = await fetch(validationEndpoint.toString())
      const data = await res.json()

      if (data.redirectUrl) {
        setWeakIdentificationRedirectUrl(data.redirectUrl)
        window.location.assign(data.redirectUrl)
      }
    } catch (error) {
      console.warn(`Error while trying to redirect: ${error}`)
    }
  }

  return (
    <StyledSuccessSimpleWrapper>
      <Heading level={'h4'} color={'bf-blue'}>
        {t('weak-identification.success-title')}
      </Heading>
      <Spacer size={'large'} />
      <Paragraph>{t('weak-identification.success-description')}</Paragraph>
      <Spacer size={'large'} />
      <RoundedButton
        onClick={() => handleRedirect()}
        variant={RoundedButtonVariants.primary}
        type={'button'}
      >
        {t('weak-identification.success-submit')}
      </RoundedButton>
    </StyledSuccessSimpleWrapper>
  )
}

export { SuccessSimple }
