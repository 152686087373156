import { useQuery } from '@tanstack/react-query'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router'

import me from '../../../api/me'
import Stepper from '../../../components/Stepper'
import { IStep } from '../../../components/Stepper/Stepper.interface'
import { QUERY_KEY_USER_ENABLED } from '../../../constants'
import RegistrationRouteWrapper from '../../../containers/RegistrationRouteWrapper'
import withAuth from '../../../hocs/withAuth'
import JoinOrganizationStep from './JoinOrganizationStep/JoinOrganizationStep'
import RegistrationForm from './RegistrationFormStep/RegistrationForm'
import SuccessStep from './SuccessStep'

const AccountCreation: FunctionComponent = () => {
  const { t: translate } = useTranslation()
  const isUserEnabledQuery = useQuery([QUERY_KEY_USER_ENABLED], me.amIEnabled)

  // TODO: Add loader?
  if (isUserEnabledQuery.status === 'loading') return <div>Loading</div>

  // Check for disabled user
  if (isUserEnabledQuery.data?.status) {
    if (isUserEnabledQuery.data.status === 'enabled') return <Navigate to="/sso?prompt=login" />

    if (isUserEnabledQuery.data.status === 'disabled') return <div>User account disabled</div>
  }

  const steps: Array<IStep> = [
    {
      label: translate('account-creation.title'),
      component: RegistrationForm,
    },
    {
      label: translate('join-organization.title'),
      component: JoinOrganizationStep,
    },
    {
      label: 'Success step',
      component: SuccessStep,
    },
  ]

  return (
    <RegistrationRouteWrapper title={translate('account-creation.title')}>
      <Stepper>
        {steps.map(step => (
          <Stepper.Step key={step.label} {...step} />
        ))}
      </Stepper>
    </RegistrationRouteWrapper>
  )
}

export default withAuth(AccountCreation)
