import { useTranslation } from 'react-i18next'

import { Wrapper } from '../../assets/styles/GlobalStyles'
import Logo from '../Logo/Logo'
import {
  FooterContainer,
  Section,
  SectionLink,
  SectionText,
  SectionTitle,
  VerticalDivider,
} from './Footer.style'

const Footer = () => {
  const { t } = useTranslation()

  const faq = t('footer.faq-url')
  const accessibility = t('footer.accessibility-url')
  const privacyPolicyUrl = t('footer.private-policy-url')
  const accessibilityFeedback = 'mailto:saavutettavuus@businessfinland.fi'

  return (
    <Wrapper>
      <FooterContainer>
        <Section
          style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
        >
          <a href={'/'}>
            <div>
              <Logo size="medium" color="bf-blue" />
            </div>
          </a>
        </Section>
        <VerticalDivider />
        <Section>
          <SectionTitle>{t('footer.support-telephone')}</SectionTitle>
          <SectionText>
            <a href={t('footer.support-link-url') || undefined}>{t('footer.support-link-label')}</a>
          </SectionText>
          <SectionText>
            <a href={'tel:+358294695800'}>+358 29 469 5800</a>
          </SectionText>
        </Section>
        <VerticalDivider />
        <Section>
          <SectionTitle>{t('footer.visiting-address')}</SectionTitle>
          <SectionText>Business Finland</SectionText>
          <SectionText>
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://www.google.com/maps/place/Porkkalankatu+1,+00180+Helsinki'}
            >
              Porkkalankatu 1 Helsinki
            </a>
          </SectionText>
        </Section>
        <VerticalDivider />
        <Section>
          <SectionLink>
            <a href={faq} target="_blank" rel="noreferrer" style={{ display: 'none' }}>
              {'FAQ'}
            </a>
            <a href={accessibility} target="_blank" rel="noreferrer">
              {t('footer.accessibility')}
            </a>
            <br />
            <a href={accessibilityFeedback} target="_blank" rel="noreferrer">
              {t('footer.accessibility-feedback')}
            </a>
            <br />
            <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
              {t('footer.privacy-policy')}
            </a>
          </SectionLink>
        </Section>
      </FooterContainer>
    </Wrapper>
  )
}

export default Footer
