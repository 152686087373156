import { FunctionComponent, useMemo } from 'react'

import { ColorProp } from '../../shared/types'
import { colorPropToHex } from '../../utils/style-utils'
import { isExternalUrl } from '../../utils/url-utils'
import ExternalLink from './ExternalLink'
import InternalLink from './InternalLink'
import ILink from './Link.interface'

const Link: FunctionComponent<ILink> = ({ href, color = 'bf-blue', children, ...rest }) => {
  const isExternal = useMemo(() => isExternalUrl(href), [href])
  const LinkComponent = useMemo(() => (isExternal ? ExternalLink : InternalLink), [isExternal])
  const textColor = colorPropToHex(color as ColorProp)

  return (
    <LinkComponent href={href} style={{ color: textColor }} {...rest} color={color}>
      {children}
    </LinkComponent>
  )
}

export default Link
