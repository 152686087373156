enum ValidationKeys {
  Email = 'VALIDATE_EMAIL',
}

type ValidateEmailResponse = {
  errors: {
    field: string
    message: string
  }[]
  message: string
}

const validateEmail = async (email: string): Promise<ValidateEmailResponse> => {
  const response = await fetch('/api/validations/email', {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return await response.json()
}

export { validateEmail, ValidationKeys }
