import { SESSION_KEY_REDIRECT, SESSION_KEY_SERVICE } from '../../../constants'
import { SessionStorageKeys } from './WeakIdentification.schema'
import { WeakIdentificationUrls } from './WeakIdentification.urls'

/**
 * Sets the redirect url for the weak identication.
 * @param mode
 */
export function setWeakIdentificationRedirectUrl(url?: string): void {
  sessionStorage.setItem(SESSION_KEY_REDIRECT, url || WeakIdentificationUrls.miscInformationStep)
}

/**
 * Gets the redirect url for the weak identication.
 * @returns {string | null}
 */
export function getWeakIdentificationRedirectUrl(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.redirectUrl)
}

/**
 * Sets the selected business id.
 * @param businessId
 */
export function setSelectedBusinessId(businessId: string) {
  sessionStorage.setItem(SessionStorageKeys.selectedBusinessId, businessId)
}

/**
 * Deletes the selected business id.
 */
export function deleteSelectedBusinessId() {
  sessionStorage.removeItem(SessionStorageKeys.selectedBusinessId)
}

/**
 * Gets the selected business id.
 * @returns {string | null}
 */
export function getSelectedBusinessId(): string | null {
  return sessionStorage.getItem(SessionStorageKeys.selectedBusinessId)
}

/**
 * Gets stored session service id
 * @returns {string | null}
 */
export function getServiceId(): string | null {
  const service = sessionStorage.getItem(SESSION_KEY_SERVICE)

  return service ? JSON.parse(service).id : null
}
